// libs
import React,{useState} from 'react';
import { useForm } from "react-hook-form";
import md5 from 'crypto-js/md5';
import { ToastContainer, toast } from 'react-toastify';
// components 
import Logo from '../../components/logo';
import '../../assets/css/core.css';
import Footer from '../../components/footer';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import Config from '../../config';

function Login({setToken}) {

    const [Loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const onSubmit = async (data) => { 
        setLoading(true);
        let validate = await ajaxCall.postCall(`${Config.api.generic}/account/login`, {
            email: data.username,
            password: md5(data.password).toString()
        });

        if(validate && validate.status) {
            if(validate.context.message.hasOwnProperty('data') && validate.context.message.data.length > 0) {
                reset();
                setToken(validate.context.message.data[0]);
                toast.info('Login validated. Redirecting...');
                setTimeout(() => {
                    window.location.href = "/dashboard";
                }, 1500);
            } else {
                toast.error('Something went wrong. Please try again');  
                setLoading(false);  
            }
        } else {
            toast.error('Invalid credentials. Please try again');
            setLoading(false);
        }
    }

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="login-form bg-light mt-4 p-4">
                            <form action="" method="" className="g-3" onSubmit={handleSubmit(onSubmit)}>
                                <div className="text-center">
                                    <Logo />
                                </div>
                                <div className="col-12">
                                    <div className="py-2">
                                        <label className="vt-txt-dark-blue">Username</label>
                                        <input type="text" className={`form-control ${errors.username && "invalid"}`}
                                            {...register("username", { required: "Username is Required" })}
                                            onKeyUp={() => {
                                                trigger("username");
                                            }} placeholder="Username" />
                                        {errors.username && (
                                            <small className="text-danger">{errors.username.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="py-2">
                                        <label className="vt-txt-dark-blue">Password</label>
                                        <input type="password" className={`form-control ${errors.password && "invalid"}`}
                                            {...register("password", { required: "Password is Required" })}
                                            onKeyUp={() => {
                                                trigger("password");
                                            }} placeholder="Password" />
                                        {errors.password && (
                                            <small className="text-danger">{errors.password.message}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="rememberMe" />
                                        <label className="form-check-label vt-txt-dark-blue" htmlFor="rememberMe"> Remember me</label>
                                    </div>
                                </div>
                                <div className="col-12 d-grid gap-2 col-6 mx-auto">
                                    <button type="submit" disabled={(Loading)?true:false} className="vt-primary-btn btn-sm btn pb-2">{(Loading)?'Loading...':'Login'}</button>
                                </div>
                            </form>
                            <hr className="mt-4" />
                            <div className="col-12">
                                <p className="text-center mb-0 vt-txt-dark-blue">Forgot your Password? <a href="/forgot-password" className="foot-link vt-txt-dark-blue"> Reset Password</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="fixed-bottom"><Footer /></div>
        </div>
    )
}

export default Login;
