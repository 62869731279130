// libs
import React, {useEffect, useRef, useState} from 'react'
import { Modal } from 'bootstrap';
import { ToastContainer, toast } from 'react-toastify';
// components 
// ... 
// config & functions 
// ...

function ImagePreview({imagepath, handleviewimagefn}) {

    const wrapperRef = useRef(null);

    const hideModal = () => {
		const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        handleviewimagefn(false, ''); // make it 0 and remove component from parent div
	}

    useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }
        loadComponent();
    });

    return (
        <div className="modal fade" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="staticBackdropLabel">Preview Image</h5>
					<button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
				</div>
				<div className="modal-body text-center">
					<img src={imagepath} className='img-fluid' />
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
				</div>
				</div>
			</div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</div>
    )
}
export default ImagePreview;
