// libs
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faTextHeight } from '@fortawesome/free-solid-svg-icons';
import * as bootstrap from 'bootstrap';
// components 
import ConfirmationBox from '../../components/confirmation-box';
import FormModal from './form-modal';
import ImagePreview from '../../components/image-preview-box';
// config & functions 
import config from '../../config';

function Table({loadapidata, offset, list, orgInfo, token}) {
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [RowId, setRowId] = useState(0);
    const deleteUrl = `${config.api.generic}/sales`;

	const handFormModal = (status, ppid) => {
        setRowId(ppid);
        setShowFormModalBox(status);
    }

    const handleDelete = (rowId) => {
        setRowId(rowId);
        setShowDeleteModalBox(true);
    }

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-hover fs-14rem">
                    <thead>
                        <tr>
                            <th style={{minWidth: '80px'}}>ID</th>
                            <th style={{minWidth: '120px'}}>Org Name</th>
                            <th style={{minWidth: '120px'}}>Seller Account</th>
                            <th style={{minWidth: '220px'}}>Name</th>
                            <th style={{minWidth: '120px'}}>Order Date</th>
                            <th style={{minWidth: '120px'}}>Dispatch Date</th>
                            <th style={{minWidth: '100px'}}>Platform</th>
                            <th style={{minWidth: '120px'}}>Order Type</th>
                            <th style={{minWidth: '220px'}}>Order Id</th>
                            <th style={{minWidth: '200px'}}>Skuid</th>
                            <th style={{minWidth: '220px'}}>Product Name</th>
                            <th style={{minWidth: '80px'}}>Size</th>
                            <th style={{minWidth: '100px'}}>Color</th>
                            <th style={{minWidth: '80px'}}>Qty</th>
                            <th style={{minWidth: '120px'}} className="bg-light">Selling Price</th>
                            <th style={{minWidth: '160px'}} className="bg-light">Settlement Amount</th>
                            <th style={{minWidth: '100px'}} className="bg-light">Return Fees</th>
                            <th style={{minWidth: '160px'}} className="bg-light">Penalty Amount</th>
                            <th style={{minWidth: '120px'}}>Is Returned</th>
                            <th style={{minWidth: '120px'}}>Return Type</th>
                            <th style={{minWidth: '150px'}}>Return Arrival Date</th>
                            <th style={{minWidth: '180px'}}>Return Delivery Status</th>
                            <th style={{minWidth: '220px'}}>Return Reason</th>
                            <th style={{minWidth: '140px'}}>Claim for Refund</th>
                            <th style={{minWidth: '120px'}}>Claim Status</th>
                            <th style={{minWidth: '200px'}}>Reason of Claim Rejection</th>
                            <th style={{minWidth: '150px'}}>Available to Sell</th>
                            <th style={{minWidth: '180px'}}>Reason for not to Resell</th>
                            <th style={{minWidth: '180px'}}>Comments</th>
                            <th style={{minWidth: '120px'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(data => {
                            return <tr key={Math.random()} style={{color: (data.is_returned && data.is_returned === 'Yes')? `#ff0000`:`#000`}}>
                                <td>{data.pk_id}</td>
                                <td>{data.org_account_label}</td>
                                <td>{data.seller_org_account_label}</td>
                                <td>
                                    <div>{data.customer_name} - {data.customer_city} {data.customer_state} {data.customer_pincode}</div>
                                    <div className="fs-10rem">{data.customer_address}</div>
                                </td>
                                <td>{data.order_date}</td>
                                <td>{data.dispatch_date}</td>
                                <td>{data.platform_type_label}</td>
                                <td>{data.order_type_label}</td>
                                <td>
                                    <div>{data.orderid}</div>
                                    <div className="fs-11rem">Item Id: {data.itemid || '-'}</div>
                                </td>
                                <td>{data.skuid}</td>
                                <td>{data.product_name}</td>
                                <td>{data.size_label}</td>
                                <td>{data.color_label}</td>
                                <td>{data.quantity}</td>
                                <td className="bg-light">Rs {data.selling_price}/-</td>
                                <td className="bg-light">Rs {data.settlement_amount}/-</td>
                                <td className="bg-light">Rs {data.return_fees}/-</td>
                                <td className="bg-light">Rs {data.penalty_amount}/-</td>
                                <td>{data.is_returned}</td>
                                <td>{data.return_type_label}</td>
                                <td>{data.return_arrival_date}</td>
                                <td>{data.return_delivery_status_label}</td>
                                <td>{data.return_reason}</td>
                                <td>{data.claim_for_refund}</td>
                                <td>{data.claim_status_label}</td>
                                <td>{data.reason_for_claim_rejection}</td>
                                <td>{data.available_to_sell}</td>
                                <td>{data.reason_for_not_to_sell}</td>
                                <td>{data.comments}</td>
                                <td>
                                    <div style={{width: '100px'}} className="d-flex">
                                        <button onClick={() => handFormModal(true, data.pk_id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faPen} className="dashboard-card-icon" /></button>
                                        <button onClick={() => handleDelete(data.pk_id)} className="me-2 border-0 bg-transparent"><FontAwesomeIcon icon={faTrash} className="dashboard-card-icon text-danger" /></button>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            {(ShowDeleteModalBox) ? <ConfirmationBox loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={{id:RowId}} token={token} /> : null}
            {(ShowFormModalBox) ? <FormModal loadapidata={loadapidata} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} rowid={RowId} token={token} orginfo={orgInfo} /> : null}
        </>
    } else {
        return null;
    }
}

export default Table;