// libs
import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as bootstrap from 'bootstrap';
// components 
import ImagePreview from '../../components/image-preview-box';
// config & functions 
// ...

function Table({list, orgInfo, token}) {

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        return () => {
            tooltipList.map(t => t.dispose())
        }
    }, [])

    if(list && Array.isArray(list) && list.length > 0) {
        return <>
            <div className="table-responsive">
                <table className="table table-hover fs-14rem">
                    <thead>
                        <tr>
                            <th style={{minWidth: '80px'}}>ID</th>
                            <th style={{minWidth: '120px'}}>SKU</th>
                            <th style={{minWidth: '120px'}}>Total Settled</th>
                            <th style={{minWidth: '220px'}}>
                                <div>Inward</div>
                                <div>Min Price - Max Price</div>
                            </th>
                            <th style={{minWidth: '200px'}}>
                                <div>Settlement</div>
                                <div>Min Price - Max Price</div>
                            </th>
                            <th style={{minWidth: '140px'}}>
                                <div>Profit</div>
                                <div>With Min Price</div>
                            </th>
                            <th style={{minWidth: '140px'}}>
                                <div>Profit</div>
                                <div>With Max Price</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((data, i) => {
                            return <tr key={Math.random()}>
                                <td>{i+1}</td>
                                <td>{data && data.skuid}</td>
                                <td>{data && data.total_dispatched}</td>
                                <td>Rs {data && data.min_inventory_amount} - Rs {data && data.max_inventory_amount}/-</td>
                                <td>Rs {data && data.min_settlement_amount} - Rs {data && data.max_settlement_amount}/-</td>
                                <td>Rs {data && Number((data?.min_settlement_amount - data?.min_inventory_amount) * data?.total_dispatched).toFixed(2)}</td>
                                <td>Rs {data && Number((data?.max_settlement_amount - data?.max_inventory_amount) * data?.total_dispatched).toFixed(2)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    } else {
        return null;
    }
}

export default Table;