// libs
import React, {useEffect, useState} from 'react'
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import Navbar from "../../components/navbar";
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Table from './table';
import Record404 from '../../components/record404';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function List({token, orgInfo}) {
	let query = utils.useQuery();
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [AccountNames, setAccountNames] = useState(null);
	// get query param
	let paginationNo = query.get("page");
	let urlAccount = query.get("account");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;
	let offset = (activePage*config.pagination.limitPerPage)-config.pagination.limitPerPage;

	const loadApiData = async (offset) => {
		setIsLoading(true);
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/sales/via-sku`, {
			offset:offset, 
			limit: config.pagination.limitPerPage, 
			fAccount: urlAccount
		}, token);
        if(apiCall && apiCall.status) {
            setListData(apiCall.context.message.data.records);
            setTotalListCount(apiCall.context.message.data.totalCount);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }

	const fetchAccountNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/get-names`, {}, token);
        if(apiCall && apiCall.status) {
            setAccountNames(apiCall.context.message.data);
        } else {
            setAccountNames("Something went wrong. Please try agian")
        }
    }

	const handlePageChange = async (pageNumber) => {
		const url = new URL(window.location.href);
		if (url.searchParams.has('page')) {
			url.searchParams.delete('page');
		} 
		url.searchParams.append('page', pageNumber);
		window.location.href = url.toString();
	}

	const handleFilterDD = (e) => {
		const url = new URL(window.location.href);
		if (url.searchParams.has(e.target.name)) {
			url.searchParams.delete(e.target.name);
		} 
		url.searchParams.delete('page');
		url.searchParams.append(e.target.name, e.target.value);
		window.location.href = url.toString();
	}

	useEffect(() => {
        async function fetchData() {
			loadApiData(offset); // offset should start from 0
        };
        fetchData();
		fetchAccountNames();
    }, []);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Sales Via SKU</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: `Sales Via SKU - (${TotalListCount})`, url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center"></div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Account</label></div>
						<select className="form-control custom-select" name="account" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0 ? AccountNames.map(d => {
								return <option key={Math.random()} selected={urlAccount==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col">
						{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <Table loadapidata={loadApiData} offset={offset} list={ListData} orgInfo={orgInfo} token={token} />}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default List;