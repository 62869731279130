// libs
import React, {useEffect, useState} from 'react'
// components
import Navbar from "../../components/navbar";
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Card from './card';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function List({token, orgInfo}) {
	const [AccountNames, setAccountNames] = useState(null);
	const [ActiveAccount, setActiveAccount] = useState(null);

	const fetchAccountNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/get-names`, {}, token);
        if(apiCall && apiCall.status) {
            setAccountNames(apiCall.context.message.data);
        } else {
            setAccountNames("Something went wrong. Please try agian")
        }
    }

	const handleFilterDD = (e) => {
		setActiveAccount(e.target.value);
	}

	useEffect(() => {
        fetchAccountNames();
    }, []);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Sales Analytics</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Sales Analytics', url: false}]} />
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type="button" onClick={() => setShowFormModalBox(true)} className="vt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">ADD SALES</button>
                        </div>
                    </div> */}
                    <hr />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-6 col-lg-4 mb-3">
							<div><label className="form-label fw-bold">Account</label></div>
							<select className="form-control custom-select" name="account" onChange={handleFilterDD}>
								<option value=""> Choose...</option>
								{AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0 ? AccountNames.map(d => {
									return <option key={Math.random()} selected={ActiveAccount==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
								}) : null}
							</select>
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Sales" subTitle="Over All Sales" apiPath="kpi/total-orders" params={{orgAccount: ActiveAccount, returnStatus: 0}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Flipkart" subTitle="Total Sales" apiPath="kpi/platform" params={{orgAccount: ActiveAccount, platformId: 1}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Amazon" subTitle="Total Sales" apiPath="kpi/platform" params={{orgAccount: ActiveAccount, platformId: 2}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Meesho" subTitle="Total Sales" apiPath="kpi/platform" params={{orgAccount: ActiveAccount, platformId: 3}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Prepaid Order" subTitle="Total Prepaid Order" apiPath="kpi/order-type" params={{orgAccount: ActiveAccount, orderTypeId: 2}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="COD Order" subTitle="Total COD Orders" apiPath="kpi/order-type" params={{orgAccount: ActiveAccount, orderTypeId: 1}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Return" subTitle="Total Returns" apiPath="kpi/orders-returns" params={{orgAccount: ActiveAccount, returnStatus: 1}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Claim Applied" subTitle="Return Claim Applied" apiPath="kpi/claim" params={{orgAccount: ActiveAccount, appliedStatus: 'Yes'}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Claim Approved" subTitle="Return Claim Approved" apiPath="kpi/claim-status" params={{orgAccount: ActiveAccount, status: 3}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Claim Rejected" subTitle="Return Claim Rejected" apiPath="kpi/claim-status" params={{orgAccount: ActiveAccount, status: 4}} preFix="" />
						</div>
						<div className="col-xl-3 col-lg-6">
							<Card token={token} title="Claim Inprocess" subTitle="Return Claim Undreprocess" apiPath="kpi/claim-status" params={{orgAccount: ActiveAccount, status: 2}} preFix="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default List;