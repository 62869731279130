import React from 'react';
import {useLocation} from "react-router-dom";
import jwtDecode from "jwt-decode";

const writeLocalStorage = async (key, data) => {
    return localStorage.setItem(key, data);
}

const readLocalStorage = async (key) => {
    return localStorage.getItem(key);
}

const removeLocalStorage = async (key) => {
    return localStorage.removeItem(key);
}

const decodeJWT = async (token) => {
    return jwtDecode(token)
}

const logout = () => {
    removeLocalStorage('token');
}

const fileNameFromPath = async (str) => {
    return str.substring(str.lastIndexOf('/')+1); // this line has hardcoded in components/gallery-box.js file to avoid promise function inside return
}

const redirect = (url) => {
	window.location.href = url;
}

const openInNewTab = (url) => {
	window.open(url, '_blank');
}

const useQuery = () => {
	const { search } = useLocation(); 
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const urlFriendlyStr = async (str) => {
    return str.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();;
}

const isJson = async(val) => {
    try {
        JSON.parse(val);
    } catch (e) {
        return false;
    }
    return true;
}

const getDateFromTimestamp = (selectedDate) => {
    if(selectedDate) {
        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(selectedDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    } else {
        return `0000-00-00`
    }
}

export default {
    writeLocalStorage, 
    readLocalStorage,
    removeLocalStorage,
    decodeJWT,
    logout,
    fileNameFromPath,
    useQuery,
    redirect,
    openInNewTab,
    isNumeric,
    urlFriendlyStr,
    isJson,
    getDateFromTimestamp
}