import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import Loading from '../../components/loading';

function Card({token, title, subTitle, apiPath, params, preFix}) {
    const [TotalCount, setTotalCount] = useState(0);
    const [IsLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            let apiCall = await ajaxCall.getCall(`${config.api.generic}/${apiPath}`, params, token);
            if(apiCall && apiCall.status) {
                setIsLoading(false);
                setTotalCount(apiCall.context.message.data[0].total_count)
            } else {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [params.orgAccount]);

    return <div className="card card-stats mb-4 mt-4 mb-xl-0">
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <h5 className="card-title text-uppercase text-muted mb-0">{title}</h5>
                    {IsLoading?<Loading />:<div className="h2 font-weight-bold mb-0">{preFix} {TotalCount}</div>}
                </div>
                <div className="col-auto">
                    <div className="icon icon-shape ">
                        <FontAwesomeIcon icon={faEnvelope} className="dashboard-card-icon" />
                    </div>
                </div>
            </div>
            <p className="mt-3 mb-0 text-muted text-sm"><span className="text-nowrap">{subTitle}</span></p>
        </div>
    </div>
}

export default Card;