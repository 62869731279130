// libs
import React, { useState, useEffect } from 'react';
// components 
import FormModal from './form-modal';
import ProductFormModal from '../products/product/form-modal';
import ProductVariantListModal from '../products/variant-modal/list-modal';
import Loading from '../../components/loading';
import ConfirmationBox from '../../components/confirmation-box';
import ImagePreview from '../../components/image-preview-box';
// config & functions 
import ajaxCall from '../../utils/ajaxcall'
import config from '../../config';

const Accordion = ({list, orgInfo, token}) => {
    
    const [ProductPID, setProductPID] = useState(0);
    const [ChildRowId, setChildRowId] = useState(0);
    const [ShowFormModalBox, setShowFormModalBox] = useState(false);
    const [ShowDeleteModalBox, setShowDeleteModalBox] = useState(false);
    const [ShowProductDetailBox, setShowProductDetailBox] = useState(false);
    const [ShowVariantModalBox, setShowVariantModalBox] = useState(false);
    const [IsActiveAccord, setIsActiveAccord] = useState(null);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);
    const deleteUrl = `${config.api.generic}/winsight/analysis`;

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orgInfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    const PrintImage = ({img}) => {
        if(img) {
            let sa = img.split(",");
            let fin = (Array.isArray(sa) && sa.length > 0) ? sa[0] : "";
            return <div className='bg-secondary rounded-circle d-flex justify-content-center align-items-center' style={{minWidth: '40px', width: '40px', height: '40px'}} onClick={e => handleViewImage(true, fin)}>
                <img src={`${config.assets.products}/${orgInfo.domain}/products/thumbnail/${fin}`} className='img-fluid' style={{minWidth: '40px', width: '40px', height: '40px'}} />
            </div>
        } else {
            return <div className='bg-secondary rounded-circle' style={{minWidth: '40px', width: '40px', height: '40px'}}></div>
        }
    }

    const handFormModal = (status, ppid, childid) => {
        setChildRowId(childid);
        setProductPID(ppid);
        setShowFormModalBox(status);
    }
    
    const handDeleteRowBox = (status, ppid, childid) => {
        setChildRowId(childid);
        setProductPID(ppid);
        setShowDeleteModalBox(status);
    }

    const handleProductDetails = (ppid) => {
        setProductPID(ppid);
        setShowProductDetailBox(true);
    }
    
    const handleVariantDetails = (ppid) => {
        setProductPID(ppid);
        setShowVariantModalBox(true);
    }

    const LoopProducts = () => {
        const [AnalysisData, setAnalysisData] = useState([]);
        const [IsError, setIsError] = useState(false);
        const [IsLoading, setIsLoading] = useState(true);

        useEffect(() => {
            const getAnalysis = async () => {
                setIsLoading(true);
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/winsight/analysis`, {
                    productPID: (IsActiveAccord) ? IsActiveAccord : 0
                }, token);
                if(apiCall && apiCall.status) {
                    if(apiCall.context.hasOwnProperty('data') && Array.isArray(apiCall.context.message.data) && apiCall.context.message.data.length > 0) {
                        setAnalysisData(apiCall.context.message.data);
                        setIsError(false);
                        setIsLoading(false);
                    } else {
                        setIsError(true);
                        setIsLoading(false);
                    }
                } else {
                    setIsError(true);
                    setIsLoading(false);
                }
            }
            getAnalysis();
        }, []);

        if(IsError) {
            return <div className='fs-14rem text-center text-danger mb-3'>No products available to show</div>;
        } else if (IsLoading) {
            return <Loading />
        } else {
            if(AnalysisData && Array.isArray(AnalysisData) && AnalysisData.length > 0) {
                return <div className="table-responsive">
                    <table className="table table-striped table-hover border">
                        <thead>
                            <tr>
                            <th scope="col">Platform</th>
                            <th style={{minWidth: '160px'}} scope="col">Product Name</th>
                            <th style={{minWidth: '130px'}} scope="col">Mrp</th>
                            <th scope="col">Discount</th>
                            <th style={{minWidth: '160px'}} scope="col">After Discount</th>
                            <th style={{minWidth: '100px'}} scope="col">Units Sold</th>
                            <th style={{minWidth: '158px'}} scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {AnalysisData.map(ele => {
                                return <tr key={Math.random()}>
                                    <th scope="row" className='fs-14rem'>{ele.platform}</th>
                                    <td><a href={`${ele.link}`} className='fs-14rem' target='_blank'>{ele.name}</a></td>
                                    <td className='fs-14rem'>Rs {ele.mrp}/-</td>
                                    <td className='fs-14rem'>{ele.discount}%</td>
                                    <td className='fs-14rem'>Rs {ele.discounted_price}/-</td>
                                    <td className='fs-14rem'>{ele.units_sold}</td>
                                    <td className='fs-14rem'>
                                        <button className='btn btn-sm border-dark me-2' onClick={() => handFormModal(true, IsActiveAccord, ele.pk_id)}>Edit</button>
                                        <button className='btn btn-sm border-danger text-danger' onClick={() => handDeleteRowBox(true, IsActiveAccord, ele.pk_id)}>Delete</button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            } else {
                return <div className='fs-14rem text-center text-danger mb-3'>No products available to show</div>;
            }
        }
    }

    const ListProducts = ({productpid, asp, name, image, color, status}) => {
        let statusColor = "text-black";
        if(status === "Active") {
            statusColor = "text-success"
        } else if (status === "Inactive") {
            statusColor = "text-danger"
        }

        return <React.Fragment>
            <div key={Math.random()} className="d-flex flex-column border mb-2 rounded shadow-sm cursor-pointer">
                <div className="d-flex justify-content-between p-2" onClick={() => setIsActiveAccord(productpid)}>
                    <div className='d-flex align-items-center'><PrintImage img={image} /> <div>
                            <div className="px-2"><span className='fs-14rem'>{name}</span> | <span className='fs-14rem'>{color}</span> | <span className={`${statusColor} fw-bold fs-14rem`}>{status}</span> | <button type="button" onClick={() => handleProductDetails(productpid)} className="btn btn-sm bg-white border-0 vt-txt-dark-orange">View Product</button> | <button type="button" onClick={() => handleVariantDetails(productpid)} className="btn btn-sm bg-white border-0 vt-txt-dark-orange">View Variant</button></div>
                            <div className='mx-2 fs-14rem vt-txt-dark-orange fw-bold'>Rs {asp || 0}/-</div>
                        </div>
                    </div>
                    <div className='fw-bold'>{(IsActiveAccord && IsActiveAccord === productpid) ? '-' : '+'}</div>
                </div>
                {IsActiveAccord && IsActiveAccord === productpid && <div className="p-2 fs-14rem text-center">
                    <LoopProducts />
                    <button type='button' onClick={() => {handFormModal(true, productpid, '')}} className='btn btn-sm vt-primary-btn'>Add Variant</button>
                </div>}
            </div>
        </React.Fragment>
    }

    if(list && list.hasOwnProperty('records') && Array.isArray(list.records) && list.records.length > 0) {
        return (
            <div>
                <div className="accordion">
                    {list.records.map(({ id, average_selling_price, name, image, color_label, product_status_label }) => (
                        <ListProducts key={Math.random()} productpid={id} asp={average_selling_price} name={name} image={image} color={color_label} status={product_status_label} />
                    ))}
                </div>
                {(ShowFormModalBox) ? <FormModal setshowformmodalbox={setShowFormModalBox} productpid={ProductPID} childrowid={ChildRowId} token={token} /> : null}
                {(ShowDeleteModalBox) ? <ConfirmationBox setshowdeletemodalbox={setShowDeleteModalBox} url={deleteUrl} params={{productPID: ProductPID, childRowID: ChildRowId}} token={token} /> : null}
                {(ShowProductDetailBox) ? <ProductFormModal setshowformmodalbox={setShowProductDetailBox} productid={ProductPID} token={token} orginfo={orgInfo} /> : null}
                {(ShowVariantModalBox) ? <ProductVariantListModal showvariantmodalbox={setShowVariantModalBox} productid={ProductPID} token={token} orginfo={orgInfo} /> : null}
                {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
            </div>
        );
    } else {
        return <div>No Data Available</div>;
    }
};

export default Accordion;