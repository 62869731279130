// libs
import React, {useEffect, useState} from 'react'
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components
import Navbar from "../../components/navbar";
import FormModal from './form-modal';
import "../../assets/css/style.css";
import Breadcrumb from "../../components/breadcrumb";
import Loading from '../../components/loading';
import Table from './table';
import Record404 from '../../components/record404';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';

function List({token, orgInfo}) {
	let query = utils.useQuery();
	const [ShowFormModalBox, setShowFormModalBox] = useState(false);
	const [ListData, setListData] = useState([]);
	const [TotalListCount, setTotalListCount] = useState(0);
	const [IsLoading, setIsLoading] = useState(true);
	const [Is404, setIs404] = useState(false);
	const [AccountNames, setAccountNames] = useState(null);
	const [PlatformList, setPlatformList] = useState(null);
	const [OrderType, setOrderType] = useState(null);
	const [ClaimStatus, setClaimStatus] = useState(null);
	const [SearchQInputVal, setSearchQInputVal] = useState(null);
	const [ReturnDeliveryStatus, setReturnDeliveryStatus] = useState('');
    const [ReturnType, setReturnType] = useState('');
    const [Kpi, setKpi] = useState('');
	// get query param
	let paginationNo = query.get("page");
	let urlAccount = query.get("account");
	let urlSeller = query.get("seller");
	let urlPlatform = query.get("platform");
	let urlOrderType = query.get("orderType");
	let urlClaimStatus = query.get("claimStatus");
	let urlIsReturned = query.get("isReturned");
	let urlTotalDamaged = query.get("totalDamaged");
	let urlReturnType = query.get("returnType");
	let urlStartDate = query.get("startDate");
	let urlEndDate = query.get("endDate");
	let urlPriceRecord = query.get("priceRecord");
	let urlReturnDeliveryStatus = query.get("returnDeliveryStatus");
	let urlSearchQ = query.get("searchQ");
	let activePage = (utils.isNumeric(paginationNo)) ? parseInt(paginationNo) : 1;
	let offset = (activePage*config.pagination.limitPerPage)-config.pagination.limitPerPage;
	// Used for date lib
	let formatedSD = (urlStartDate && urlStartDate !== '0000-00-00') ? new Date(urlStartDate) : '';
	let formatedED = (urlEndDate && urlEndDate !== '0000-00-00') ? new Date(urlEndDate) : '';

	const loadApiData = async (offset) => {
		setIsLoading(true);
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/sales`, {
			offset:offset, 
			limit: config.pagination.limitPerPage, 
			fAccount: urlAccount, 
			fSeller: urlSeller, 
			fPlatform: urlPlatform, 
			fIsReturned: urlIsReturned, 
			fOrderType: urlOrderType, 
			fClaimStatus: urlClaimStatus, 
			fSearchQ: urlSearchQ, 
			fTotalDamage: urlTotalDamaged, 
			fReturnType: urlReturnType, 
			fUrlStartDate: urlStartDate,
			fUrlEndDate: urlEndDate,
			fPriceRecord: urlPriceRecord,
			fUrlReturnDeliveryStatus: urlReturnDeliveryStatus
		}, token);
        if(apiCall && apiCall.status) {
            setListData(apiCall.context.message.data.records);
            setTotalListCount(apiCall.context.message.data.totalCount);
            setKpi(apiCall.context.message.data.getKPI);
            setIsLoading(false);
			setIs404(false);
        } else {
            setIsLoading(false);
			setIs404(true);
        }
    }

	const fetchAccountNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/get-names`, {}, token);
        if(apiCall && apiCall.status) {
            setAccountNames(apiCall.context.message.data);
        } else {
            setAccountNames("Something went wrong. Please try agian")
        }
    }

	const fetchPlatformList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/platform`, {}, token);
        if(apiCall && apiCall.status) {
            setPlatformList(apiCall.context.message.data);
        } else {
            setPlatformList("Something went wrong. Please try agian")
        }
    }

	const fetchOrderType = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/order-type`, {}, token);
        if(apiCall && apiCall.status) {
            setOrderType(apiCall.context.message.data);
        } else {
            setOrderType("Something went wrong. Please try agian")
        }
    }

	const fetchClaimStatus = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/claim-status`, {}, token);
        if(apiCall && apiCall.status) {
            setClaimStatus(apiCall.context.message.data);
        } else {
            setClaimStatus("Something went wrong. Please try agian")
        }
    }

	const handlePageChange = async (pageNumber) => {
		const url = new URL(window.location.href);
		if (url.searchParams.has('page')) {
			url.searchParams.delete('page');
		} 
		url.searchParams.append('page', pageNumber);
		window.location.href = url.toString();
	}

	const handleFilterDD = (e) => {
		const url = new URL(window.location.href);
		if (url.searchParams.has(e.target.name)) {
			url.searchParams.delete(e.target.name);
		} 
		url.searchParams.delete('searchQ');
		url.searchParams.delete('page');
		url.searchParams.append(e.target.name, e.target.value);
		window.location.href = url.toString();
	}

	const handleDate = (type, d) =>  {
		let key = (type === 'sd') ? 'startDate': 'endDate';
		let nd = utils.getDateFromTimestamp(d);
		const url = new URL(window.location.href);
		if (url.searchParams.has(key)) {
			url.searchParams.delete(key);
		} 
		url.searchParams.delete('searchQ');
		url.searchParams.delete('page');
		url.searchParams.append(key, nd);
		window.location.href = url.toString();
	}

	const handleChangeSearchQuery = (event) => {
		setSearchQInputVal(event.target.value);
	}

	const fetchReturnType = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/order/return-type`, {}, token);
        if(apiCall && apiCall.status) {
            setReturnType(apiCall.context.message.data);
        } else {
            setReturnType("Something went wrong. Please try agian")
        }
    }

    const fetchReturnDeliveryStatus = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/order/return-delivery-status`, {}, token);
        if(apiCall && apiCall.status) {
            setReturnDeliveryStatus(apiCall.context.message.data);
        } else {
            setReturnDeliveryStatus("Something went wrong. Please try agian")
        }
    }

	const submitSearchQuery = () => {
		const url = new URL(window.location.href);
		if (url.searchParams.has('searchQ')) {
			url.searchParams.delete('searchQ');
		} 
		url.searchParams.delete('platform');;
		url.searchParams.delete('orderType');
		url.searchParams.delete('claimStatus');
		url.searchParams.delete('isReturned')
		url.searchParams.delete('totalDamaged');
		url.searchParams.delete('returnType');
		url.searchParams.delete('returnDeliveryStatus');
		url.searchParams.delete('startDate');
		url.searchParams.delete('endDate');
		url.searchParams.delete('priceRecord');
		url.searchParams.delete('page');
		url.searchParams.append('searchQ', SearchQInputVal);
		window.location.href = url.toString();
	} 

	useEffect(() => {
        async function fetchData() {
			loadApiData(offset); // offset should start from 0
        };
        fetchData();
		fetchAccountNames();
		fetchPlatformList();
		fetchOrderType();
        fetchClaimStatus();
		fetchReturnType();
        fetchReturnDeliveryStatus();
    }, []);

	return (
		<>
			<Navbar orgInfo={orgInfo} />
			<div className="container">
				<div className="row">
					<div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Sales List</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: `Sales - (${TotalListCount})`, url: false}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type="button" onClick={() => setShowFormModalBox(true)} className="vt-primary-btn btn-md btn btn-outline-none text-white fs-14rem">ADD SALES</button>
                        </div>
                    </div>
                    <hr />
				</div>
				<div className="row">
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Account</label></div>
						<select className="form-control custom-select" name="account" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0 ? AccountNames.map(d => {
								return <option key={Math.random()} selected={urlAccount==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Seller</label></div>
						<select className="form-control custom-select" name="seller" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0 ? AccountNames.map(d => {
								return <option key={Math.random()} selected={urlSeller==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-2 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Platform</label></div>
						<select className="form-control custom-select" name="platform" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{PlatformList && Array.isArray(PlatformList) && PlatformList.length > 0 ? PlatformList.map(d => {
								return <option key={Math.random()} selected={urlPlatform==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-2 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Order Type</label></div>
						<select className="form-control custom-select" name="orderType" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{OrderType && Array.isArray(OrderType) && OrderType.length > 0 ? OrderType.map(d => {
								return <option key={Math.random()} selected={urlOrderType==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-2 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Is Returned</label></div>
						<select className="form-control custom-select" name="isReturned" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							<option value="Yes" selected={urlIsReturned=="Yes"?true:false} > Yes</option>
							<option value="No" selected={urlIsReturned=="No"?true:false} > No</option>
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Claim Status</label></div>
						<select className="form-control custom-select" name="claimStatus" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{ClaimStatus && Array.isArray(ClaimStatus) && ClaimStatus.length > 0 ? ClaimStatus.map(d => {
								return <option key={Math.random()} selected={urlClaimStatus==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Total Damaged</label></div>
						<select className="form-control custom-select" name="totalDamaged" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							<option value="Yes" selected={urlTotalDamaged=="Yes"?true:false} > Show Damaged</option>
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Return Type</label></div>
						<select className="form-control custom-select" name="returnType" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{ReturnType && Array.isArray(ReturnType) && ReturnType.length > 0 ? ReturnType.map(d => {
								return <option key={Math.random()} selected={urlReturnType==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Return Delivered Status</label></div>
						<select className="form-control custom-select" name="returnDeliveryStatus" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							{ReturnDeliveryStatus && Array.isArray(ReturnDeliveryStatus) && ReturnDeliveryStatus.length > 0 ? ReturnDeliveryStatus.map(d => {
								return <option key={Math.random()} selected={urlReturnDeliveryStatus==d.pk_id?true:false} value={d.pk_id}>{d.label}</option>
							}) : null}
						</select>
					</div>
					<div className="col-6 col-md-6 col-lg-2 mb-3">
						<div><label htmlFor="orderDate" className="form-label fs-14rem fw-bold">Start Date</label></div>
						<div className="react-datepicker-wrapper-fullwidth">
							<DatePicker
								className="form-control w-100"
								selected={formatedSD}
								format="DD" 
								onChange={(date) => {
									handleDate('sd', date)
								}}
							/>
						</div>
					</div>
					<div className="col-6 col-md-6 col-lg-2 mb-3">
						<div><label htmlFor="orderDate" className="form-label fs-14rem fw-bold">End Date</label></div>
						<div className="react-datepicker-wrapper-fullwidth">
							<DatePicker
								className="form-control w-100"
								selected={formatedED}
								format="DD" 
								onChange={(date) => {
									handleDate('ed', date)
								}}
							/>
						</div>
					</div>
					<div className="col-6 col-md-6 col-lg-3 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Price Record</label></div>
						<select className="form-control custom-select" name="priceRecord" onChange={handleFilterDD}>
							<option value=""> Choose...</option>
							<option value="settled" selected={urlPriceRecord=="settled"?true:false} > Settled Records</option>
							<option value="unsettled" selected={urlPriceRecord=="unsettled"?true:false} > Unsettled Records</option>
						</select>
					</div>
					<div className="col-12 col-md-6 col-lg-5 mb-3">
						<div><label className="form-label fs-13rem fw-bold">Order Id / Item Id / SKU</label></div>
						<div className="input-group">
							<input type="text" className="form-control" defaultValue={urlSearchQ} onKeyUp={handleChangeSearchQuery} placeholder="Order Id / Item Id / SKU" aria-label="Order Id / Item Id / SKU" aria-describedby="basic-addon2" />
							<div className="input-group-append">
								<button className="btn btn-outline-secondary" onClick={submitSearchQuery} type="button">Search</button>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col">
						<p className="mb-0 fs-14rem"><strong>Total Selling Price: </strong> Rs {Kpi && Kpi?.sellingPrice || 0}/- | <strong>Total Settlement Amount: </strong> {Kpi && Kpi?.settlementAmt || 0}/- | <strong>Total Return Fees: </strong> Rs {Kpi && Kpi?.returnFees || 0}/- | <strong>Total Panelty Fees: </strong> Rs {Kpi && Kpi?.paneltyAmt || 0}/- </p>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col">
						{(IsLoading) ? <Loading /> : (Is404) ? <Record404 /> : <Table loadapidata={loadApiData} offset={offset} list={ListData} orgInfo={orgInfo} token={token} />}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-center my-2">
							<Pagination
								activePage={activePage}
								itemsCountPerPage={config.pagination.limitPerPage}
								totalItemsCount={parseInt(TotalListCount)}
								pageRangeDisplayed={5}
								onChange={(e) => handlePageChange(e)}
								innerClass="pagination" 
								itemClass="page-item" 
								linkClass="page-link" 
								activeClass="active"
							/>
						</div>
					</div>
				</div>
			</div>
			{(ShowFormModalBox) ? <FormModal loadapidata={loadApiData} apidataparams={(offset == 1) ? 0 : offset} setshowformmodalbox={setShowFormModalBox} productid={0} token={token} orginfo={orgInfo} /> : null}
		</>
	)
}

export default List;