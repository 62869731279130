// libs
import React, {useEffect, useRef, useState} from 'react'
import { Modal } from 'bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components 
// ... 
// config & functions 
import ajaxCall from '../utils/ajaxcall';
import config from '../config';
import utils from '../utils';

function PublicUrlModal({setshowpublicurlmodal, loadapidata='', apidataparams='', params='', token}) {

    const [InputFields, setInputFields] = useState({
        refName: "",
        status: "",
        expireDate: "",
        urlIds: "",
        placeHolder: "",
        permission: ""
    });
    const [Notification, setNotification] = useState({ status: (params && params.id) ? true : false, message: 'Please wait while we fetch your record' })
    const wrapperRef = useRef(null);
    const [IsLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const hideModal = () => {
		const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowpublicurlmodal(false); // make it 0 and remove component from parent div
	}

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const onSubmit = async (data) => {
        setIsLoading(true);
        let nd = utils.getDateFromTimestamp(data.expireDate);
        let postData = {
            rowId: (params && params.id) ? params.id : 0,
            refrenceName: data.refName,
            status: data.status,
            expireDate: nd,
            publicUrlIds: localStorage.getItem("PublicUrlIds"),
            placeHolder: data.placeHolder,
            permission: data.permission
        }

        let apiCall = "";
        if (params && params.id) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/public-url-products`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/public-url-products`, postData, token);
        }
        if(apiCall) {
            localStorage.removeItem("PublicUrlIds")
            toast.info('Record updated successfully');
            reset();
            setIsLoading(false);
        } else {
            toast.error('Something went wrong. Please try again :(');
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (params && params.id) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/public-url-products/row`, {
                    rowId: params.id
                }, token);
                if(apiCall) {
                    let onLoadData = {
                        rowId: params.id,
                        refName: apiCall.context.message.data[0].reference_name,
                        status: apiCall.context.message.data[0].status,
                        expireDate: new Date(apiCall.context.message.data[0].expires_on),
                        urlIds: apiCall.context.message.data[0].public_url_ids,
                        placeHolder: apiCall.context.message.data[0].placeholder,
                        permission: apiCall.context.message.data[0].permission
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }

        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }
        fetchData();
        loadComponent();
    }, []);

    return (
        <div className="modal fade" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
			<div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Public Url Config</h5>
                        <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                    </div>
                    {(Notification.status) ?
                        <div className="col-12 text-center py-3">{Notification.message}</div> : <>
                        <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="refName" className="form-label">Reference Name</label>
                                    <input type="text" defaultValue={InputFields.refName} {...register("refName", { required: true, minLength: 3, maxLength: 85 })} className="form-control" id="refName" placeholder="Instock/Outstock Validation" />
                                    <small className="form-text text-danger">
                                        {errors.refName?.type === "required" && "Reference name is mandatory"}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <Controller
                                        name='status' 
                                        defaultValue={InputFields.status}
                                        rules={{required: true}}
                                        control={control} 
                                        render={({field}) => (
                                            <select value={(field.value === 0) ? 0 : field.value || ''} className="form-control custom-select" name="status" onChange={(e) => {updateChangeEvent(e, field)}}>
                                                <option value="">Status</option>
                                                <option value={0}>Inactive</option>
                                                <option value={1}>Active</option>
                                            </select>
                                        )}
                                    />	
                                    <small className="form-text text-danger">
                                        {errors.status?.type === "required" && "Status is mandatory"}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="placeHolder" className="form-label">Placeholder</label>
                                    <input type="text" defaultValue={InputFields.placeHolder} {...register("placeHolder", { required: true, minLength: 5, maxLength: 125 })} className="form-control" id="placeHolder" placeholder="Placeholder" />
                                    <small className="form-text text-danger">
                                        {errors.placeHolder?.type === "required" && "Placeholder is mandatory"}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <div><label htmlFor="expireDate" className="form-label">Expires on (Days)</label></div>
                                    <div className="react-datepicker-wrapper-fullwidth">
                                        <Controller
                                            control={control}
                                            name="expireDate"
                                            defaultValue={InputFields.expireDate} 
                                            rules={{ required: true }}
                                            render={({ field }) => {
                                                return (
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={field.value}
                                                        format="DD" 
                                                        onChange={(date) => {
                                                            field.onChange(date);
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <small className="form-text text-danger">
                                        {errors.expireDate?.type === "required" && "Expires on is mandatory"}
                                    </small>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="permission" className="form-label">Permission</label>
                                    <Controller
                                        name='permission' 
                                        defaultValue={InputFields.permission}
                                        rules={{required: true}}
                                        control={control} 
                                        render={({field}) => (
                                            <select value={(field.value === 0) ? 0 : field.value || ''} className="form-control custom-select" name="permission" onChange={(e) => {updateChangeEvent(e, field)}}>
                                                <option value="">Permission</option>
                                                <option value={1}>Write</option>
                                                <option value={0}>Read</option>
                                            </select>
                                        )}
                                    />	
                                    <small className="form-text text-danger">
                                        {errors.permission?.type === "required" && "Permission is mandatory"}
                                    </small>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                                <button type="submit" className="btn btn-primary fs-14rem" disabled={IsLoading}>{(IsLoading)?'Loading...': (params && params.id) ? 'Update':'Create'}</button>
                            </div>
                        </form>
                    </>}
                </div>
			</div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</div>
    )
}
export default PublicUrlModal;
