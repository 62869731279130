// libs
import React, {useEffect, useRef, useState} from 'react'
import { Modal } from 'bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
// components 
// ... 
// config & functions 
import ajaxCall from '../../utils/ajaxcall'
import Config from '../../config';

function FormModal({setshowformmodalbox, productpid, childrowid, token}) {
    const [InputFields, setInputFields] = useState({
        productPID: "",
        platform: "",
        name: "",
        link: "",
        mrp: "",
        discount: "",
        discountPrice: "",
        unitsSold: ""
    });
    const wrapperRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState((childrowid) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [Notification, setNotification] = useState({ status: (childrowid) ? true : false, message: 'Please wait while we fetch your record' })
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();
    
    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowformmodalbox(false)
	}

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);

        let postData = {
            productPID: productpid,
            childRowID: childrowid,
            platform: data.platform,
            name: data.name,
            link: data.link,
            unitsSold: data.unitsSold,
            mrp: data.mrp,
            discount: data.discount,
            discountPrice: data.discountPrice
        }
        let apiCall = "";
        if(childrowid) {
            apiCall = await ajaxCall.patchCall(`${Config.api.generic}/winsight/analysis`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${Config.api.generic}/winsight/analysis`, postData, token);
        }
        
        if(apiCall && apiCall.status) {
            setSubmitButton((InputFields.productPID)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
        } else {
            setSubmitButton((InputFields.productPID)?'UPDATE RECORD':'SAVE RECORD');
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }

    useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (childrowid) {
                let apiCall = await ajaxCall.getCall(`${Config.api.generic}/winsight/analysis/row`, {
                    childRowID: (childrowid) ? childrowid : 0,
                    productPID: (productpid) ? productpid : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let onLoadData = {
                        productPID: productpid,
                        platform: apiCall.context.message.data[0].platform,
                        name: apiCall.context.message.data[0].name,
                        link: apiCall.context.message.data[0].link,
                        mrp: apiCall.context.message.data[0].mrp,
                        discount: apiCall.context.message.data[0].discount,
                        discountPrice: apiCall.context.message.data[0].discounted_price,
                        unitsSold: apiCall.context.message.data[0].units_sold
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }
        fetchData();
        loadComponent();
    }, []);

    return (
        <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
			<div className="modal-dialog">
				<div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{childrowid?'Update Record':'Add Record'}</h5>
                        <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form action="" method="" className="g-3 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                {(Notification.status) ?
                                    <div className="col-12 text-center">{Notification.message}</div> : <>
                                        <div className="col-md-12">
                                            <div className="text-left vt-txt-dark-blue">
                                                <h3><b>Product Details</b></h3>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label className="form-label fs-14rem">Platform (Flipkart, Amazon...)</label>
                                            <input type="text" defaultValue={InputFields.platform} className="form-control" name="platform" {...register("platform", { required: true, minLength: 5, maxLength: 25 })} />
                                            <small className="form-text text-danger">
                                                {errors.platform?.type === "required" && "Platform is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.platform?.type === "minLength" && "Enter atleast 5 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.platform?.type === "maxLength" && "Only 25 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label className="form-label fs-14rem">Product Name</label>
                                            <input type="text" defaultValue={InputFields.name} className="form-control" name="name" {...register("name", { required: true, minLength: 5, maxLength: 225 })} />
                                            <small className="form-text text-danger">
                                                {errors.name?.type === "required" && "Name is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.name?.type === "minLength" && "Enter atleast 5 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.name?.type === "maxLength" && "Only 225 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label className="form-label fs-14rem">Link</label>
                                            <input type="text" defaultValue={InputFields.link} className="form-control" name="link" {...register("link", { required: true, minLength: 8, maxLength: 1200 })} />
                                            <small className="form-text text-danger">
                                                {errors.link?.type === "required" && "Link is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.link?.type === "minLength" && "Enter atleast 8 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.link?.type === "maxLength" && "Only 1200 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label fs-14rem">Units Sold</label>
                                            <input type="text" defaultValue={InputFields.unitsSold} className="form-control" name="unitsSold" {...register("unitsSold", { 
                                                required: true, 
                                                minLength: 1, 
                                                maxLength: 6
                                            })} />
                                            <small className="form-text text-danger">
                                                {errors.unitsSold?.type === "required" && "Unit Sold is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.unitsSold?.type === "minLength" && "Enter atleast 1 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.unitsSold?.type === "maxLength" && "Only 5 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label fs-14rem">Mrp</label>
                                            <input type="text" defaultValue={InputFields.mrp} className="form-control" name="mrp" {...register("mrp", { 
                                                required: true, 
                                                minLength: 1, 
                                                maxLength: 5
                                            })} />
                                            <small className="form-text text-danger">
                                                {errors.mrp?.type === "required" && "MRP is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.mrp?.type === "minLength" && "Enter atleast 1 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.mrp?.type === "maxLength" && "Only 5 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label fs-14rem">Discount</label>
                                            <input type="text" defaultValue={InputFields.discount} className="form-control" name="discount" {...register("discount", { 
                                                required: true, 
                                                minLength: 1, 
                                                maxLength: 5
                                            })} />
                                            <small className="form-text text-danger">
                                                {errors.discount?.type === "required" && "Discount is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.discount?.type === "minLength" && "Enter atleast 1 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.discount?.type === "maxLength" && "Only 5 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <label className="form-label fs-14rem">Discounted Price</label>
                                            <input type="text" defaultValue={InputFields.discountPrice} className="form-control" name="discountPrice" {...register("discountPrice", { 
                                                required: true, 
                                                minLength: 1, 
                                                maxLength: 5
                                            })} />
                                            <small className="form-text text-danger">
                                                {errors.discountPrice?.type === "required" && "Discount Price is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.discountPrice?.type === "minLength" && "Enter atleast 1 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.discountPrice?.type === "maxLength" && "Only 5 characters allowed"}
                                            </small>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                        </div>
                                    </>}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
				</div>
			</div>
		</div>
    )
}
export default FormModal;
