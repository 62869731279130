// libs
import React, { useState } from 'react';
// components
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Breadcrumb from "../../components/breadcrumb";
import GalleryBox from '../../components/gallery-box';
// css
import "../../assets/css/style.css";
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';

function Image({token, orgInfo}) {

    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [BrowseImage, setBrowseImage] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = async (data) => {
        setDisableSubmitButton(true);
        // send it to backend
        const formData = new FormData();
        formData.append("file", data.fileName[0]);
        formData.append("imgLocation", data.imgLocation);
        formData.append("projectName", "inhouseEcommSAS");
        formData.append("fileNamePrefix", data.prefix);
        formData.append("clientDomain", orgInfo.domain);

        let apiCall = await ajaxCall.postCall(`${config.api.image}/upload`, formData, token);
        if(apiCall && apiCall.status) {
            toast.info('File updated successfully');
            reset();
            setDisableSubmitButton(false);
        } else {
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

	return (
        <>
            <Navbar orgInfo={orgInfo} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-left vt-txt-dark-blue">Upload Images</h3>
                            <Breadcrumb list={[{label: 'Home', url: '/'}, {label: 'Images', url: '/upload-image'}]} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="text-right d-flex justify-content-end h-100 align-items-center">
                            <button type="button" className="vt-primary-btn btn-md btn btn-outline-none text-white fs-14rem" onClick={() => setBrowseImage(true)}>VIEW IMAGES</button>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1 ">
                        <div className="py-3">
                            <div className="login-form shadow shadow-intensity-md rounded-3 mt-4 p-4">
                                <form action="" method="" className="g-3 p-md-5 p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-8 mt-3">
                                            <label className="form-label fs-14rem">Image Location</label>
                                            <Controller
                                                name='imgLocation' 
                                                defaultValue={""}
                                                rules={{required: true}}
                                                control={control} 
                                                render={({field}) => (
                                                    <select value={field.value} className="form-control custom-select" name="imgLocation" {...register("imgLocation", { required: true })} onChange={(e) => {updateChangeEvent(e, field)}}>
                                                        <option value=""> Choose...</option>
                                                        <option value="generic">Generic (Freestyle)</option>
                                                        <option value="banner">Banner (1800x750)</option>
                                                        <option value="product">Product (1200x1200)</option>
                                                        <option value="card">Card (416x416)</option>
                                                    </select>
                                                )}
                                            />	
                                            <small className="form-text text-danger">
                                                {errors.imgLocation?.type === "required" && "Please update image type"}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 mt-3">
                                            <label className="form-label fs-14rem">Prefix</label>
                                            <input type="text" className="form-control" name="prefix" {...register("prefix", { required: false, minLength: 1, maxLength: 5 })} />
                                            <small className="form-text text-danger">
                                                {errors.prefix?.type === "required" && "Prefix is mandatory"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.prefix?.type === "minLength" && "Enter atleast 1 characters"}
                                            </small>
                                            <small className="form-text text-danger">
                                                {errors.prefix?.type === "maxLength" && "Only 5 characters allowed"}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 mt-3">
                                            <label htmlFor="formFile" className="form-label fs-14rem">Choose your file. Only jpeg,png allowed</label>
                                            <input defaultValue={``} className="form-control" type="file" id="formFile" name="fileName" {...register("fileName", { required: true })} />
                                            <small className="form-text text-danger">
                                                {errors.fileName?.type === "required" && "File is mandatory"}
                                            </small>
                                        </div>
                                        <div className="col-4"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{(DisableSubmitButton)?`LOADING...`:`UPLOAD`}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {(BrowseImage)? <GalleryBox orgInfo={orgInfo} setbrowseimage={setBrowseImage} token={token} />:null}
        </>
	)
}

export default Image;