import utils from ".";

export const ErrorHandler = (error) => {
    const { request, response } = error;
    if (response) {
        const message = response.data;
        const status = response.status;
        // Temp added, need to fix this going forward
        if(status === 401) {
            utils.logout();
            window.location.href = "/login";
        } else {
            return {status: false, context: {
                message,
                status,
            }}
        }
    } else if (request) {
        //request sent but no response received
        return {status: false, context: {
            message: "Server time out",
            status: 503,
        }}
    } else {
        // Something happened in setting up the request that triggered an Error
        return {status: false, context: { 
            message: "Opps! something went wrong while setting up request", 
            status: 500
        }}
    }
}