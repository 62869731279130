// libs
import React, { useState, useRef } from 'react';
import { Modal } from 'bootstrap';
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import "../../assets/css/form.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// components 
import Loading from '../../components/loading';
import GalleryBox from '../../components/gallery-box';
import ImagePreview from '../../components/image-preview-box';
// config & functions 
import ajaxCall from '../../utils/ajaxcall';
import config from "../../config/index";
import utils from '../../utils';

function FormModal({loadapidata, apidataparams, setshowformmodalbox, rowid, token, orginfo}) {

    const [InputFields, setInputFields] = useState({
        accountId: "",
        rowId: "",
        vendorSupplier: "",
        productName: "",
        productImg: "",
        inwardQty: "",
        inwardSuffix: "",
        skuId: "",
        colorId: "",
        sizeId: "",
        amount: "",
        tax: "",
        totalAmount: "",
        deliveryDate: ""
    });
    const disabledField = (orginfo.roleId === 1 || orginfo.roleId === 2) ? false:true;
    const wrapperRef = useRef(null);
    const [SubmitButton, setSubmitButton] = useState((rowid) ? 'UPDATE RECORD' : 'SAVE RECORD');
    const [Notification, setNotification] = useState({ status: (rowid) ? true : false, message: 'Please wait while we fetch your record' })
    const [DisableSubmitButton, setDisableSubmitButton] = useState(false);
    const [AccountNames, setAccountNames] = useState(null);
    const [ColorList, setColorList] = useState([]);
    const [SizeList, setSizeList] = useState([]);
    const [BrowseImage, setBrowseImage] = useState(false);
    const [PreviewImage, setPreviewImage] = useState(false);
    const [PreviewImagePath, setPreviewImagePath] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm();

    const handleViewImage = (status, imgName) => {
        setPreviewImagePath(`${config.assets.products}/${orginfo.domain}/products/large/${imgName}`);
        setPreviewImage(status);
    }

    const hideModal = () => {
        const modalEle = wrapperRef.current
		const bsModal = Modal.getInstance(modalEle)
		bsModal.hide();
        setshowformmodalbox(false)
	}

    const updateChangeEvent = (value, field) => {
		field.onChange(value);
	}

    const handleInputState = ((key, val) => {
        const newData = { ...InputFields }
        newData[key] = val
        setInputFields(newData)
    })

    const removeProductImage = (i) => {
        const imgArrList = (InputFields.productImg) ? InputFields.productImg.split(",") : null;
        if(imgArrList.length > 0) {
            imgArrList.splice(i,1);
        }
        handleInputState('productImg', imgArrList.join()) 
    }

    const handleProductImageName=((val)=>{ 
        handleInputState('productImg', val) 
    })

    const onSubmit = async (data) => {
        setSubmitButton('LOADING...');
        setDisableSubmitButton(true);
        let nDeliveryDate = utils.getDateFromTimestamp(data.deliveryDate);
        let postData = {
            rowId: rowid,
            accountId: (rowid) ? InputFields.accountId : data.accountId,
            productName: data.productName,
            productImg: InputFields.productImg,
            vendorSupplier: (rowid) ? '' : data.vendorSupplier,
            inwardQty: (rowid) ? '' : data.inwardQty,
            inwardSuffix: (rowid) ? '' : data.inwardSuffix,
            skuId: (rowid) ? '' : data.skuId,
            colorId: (rowid) ? '' : data.colorId,
            sizeId: (rowid) ? '' : data.sizeId,
            amount: (rowid) ? '' : data.amount,
            tax: (rowid) ? '' : data.tax,
            deliveryDate: (rowid) ? '' : nDeliveryDate
        }
        let apiCall = "";
        if(InputFields.rowId) {
            apiCall = await ajaxCall.patchCall(`${config.api.generic}/inventory`, postData, token);
        } else {
            apiCall = await ajaxCall.postCall(`${config.api.generic}/inventory`, postData, token);
        }
        if(apiCall && apiCall.status) {
            setSubmitButton((InputFields.rowId)?'UPDATE RECORD':'SAVE RECORD');
            toast.info('Record updated successfully');
            reset();
            setDisableSubmitButton(false);
            loadapidata(apidataparams); // refresh list page
        } else {
            setSubmitButton((InputFields.rowId)?'UPDATE RECORD':'SAVE RECORD');
            toast.error(apiCall && apiCall.context.message || 'Something went wrong. Please try again :(');
            setDisableSubmitButton(false);
        }
    }
    
    const fetchColorList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/color`, {}, token);
        if(apiCall && apiCall.status) {
            setColorList(apiCall.context.message.data);
        }
    }

    const fetchSizeList = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/size`, {}, token);
        if(apiCall && apiCall.status) {
            setSizeList(apiCall.context.message.data);
        }
    }

    const fetchAccountNames = async () => {
        let apiCall = await ajaxCall.getCall(`${config.api.generic}/account/get-names`, {}, token);
        if(apiCall && apiCall.status) {
            setAccountNames(apiCall.context.message.data);
        } else {
            setAccountNames("Something went wrong. Please try agian")
        }
    }
    // Get data from DB - Used for Edit option
    React.useEffect(() => {
        async function loadComponent() {
            const modalEle = wrapperRef.current
            const bsModal = new Modal(modalEle, {
                backdrop: false,
                keyboard: false
            })
            bsModal.show()
        }

        async function fetchData() {
            if (rowid) {
                let apiCall = await ajaxCall.getCall(`${config.api.generic}/inventory/row`, {
                    rowId: (rowid) ? rowid : 0
                }, token);
                if(apiCall && apiCall.status) {
                    let dd = new Date();
                    if(apiCall.context.message.data[0].delivery_date && apiCall.context.message.data[0].delivery_date!=='0000-00-00') {
                        dd = new Date(apiCall.context.message.data[0].delivery_date);
                    }
                    let onLoadData = {
                        rowId: rowid,
                        accountId: apiCall.context.message.data[0].fk_org_account_id,
                        productName: apiCall.context.message.data[0].product_name,
                        productImg: apiCall.context.message.data[0].product_image,
                        vendorSupplier: "",
                        inwardQty: "",
                        inwardSuffix: "",
                        skuId: "",
                        colorId: "",
                        sizeId: "",
                        amount: "",
                        tax: "",
                        totalAmount: "",
                        deliveryDate: ""
                    }
                    setInputFields(onLoadData)
                    setNotification({ status: false })
                } else {
                    setNotification({ status: true, message: 'Sorry something went wrong while fetching record :(' })
                }
            }
        }
        fetchAccountNames();
        fetchData();
        fetchColorList();
        fetchSizeList();
        loadComponent();
    }, []);

    const imgArrList = (InputFields.productImg) ? InputFields.productImg.split(",") : null;

    return (
        <>
            <div className="modal fade show" data-dismiss="modal" ref={wrapperRef} tabIndex="-1" data-role="dialog" data-aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{rowid?'Update Inventory Record':'Add Inventory Record'}</h5>
                            <button type="button" className="btn-close" onClick={hideModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <form action="" method="" className="p-sm-1" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row">
                                            {(Notification.status) ? <div className="col-12 text-center">{Notification.message}</div> : <>
                                                <div className="col-md-12">
                                                    <div className="text-left mt-3 vt-txt-dark-blue">
                                                        <h4><b>Inventory Info</b></h4>
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label className="form-label fs-14rem fw-bold">Choose Client Account</label>
                                                    {(AccountNames && Array.isArray(AccountNames) && AccountNames.length > 0) ? <><Controller
                                                        name='accountId' 
                                                        defaultValue={InputFields.accountId}
                                                        rules={{required: true}}
                                                        control={control} 
                                                        render={({field}) => (
                                                            <select value={field.value || ""} className="form-control custom-select" name="accountId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={rowid?true:false}>
                                                                <option value=""> Choose...</option>
                                                                {AccountNames.map(d => {
                                                                    return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                })}
                                                            </select>
                                                        )}
                                                    />	
                                                    <small className="form-text text-danger">
                                                        {errors.accountId?.type === "required" && "Please choose Client"}
                                                    </small></> : (AccountNames === null) ? <Loading margin={1} /> : <div className="text-danger fs-12rem">{AccountNames}</div> }
                                                </div>
                                                <div className={`col-md-6 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Vendor Supplier Name</label>
                                                    <input type="text" defaultValue={InputFields.vendorSupplier} className="form-control" name="vendorSupplier" {...register("vendorSupplier", { required: rowid?false:true, minLength: 1, maxLength: 125 })} disabled={rowid?true:false} />
                                                    <small className="form-text text-danger">
                                                        {errors.vendorSupplier?.type === "required" && "Vendor Suppliers is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.vendorSupplier?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.vendorSupplier?.type === "maxLength" && "Only 125 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <label className="form-label fs-14rem fw-bold">Product Name</label>
                                                    <input type="text" defaultValue={InputFields.productName} className="form-control" name="productName" {...register("productName", { required: true, minLength: 1, maxLength: 122 })} disabled={disabledField} />
                                                    <small className="form-text text-danger">
                                                        {errors.productName?.type === "required" && "Product Name is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.productName?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.productName?.type === "maxLength" && "Only 122 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    {(orginfo.roleId === 1 || orginfo.roleId === 3) ? <button type="button" className="btn btn-sm btn-primary" onClick={() => setBrowseImage(true)}>Browse Gallery</button> : null}
                                                    <div className="mt-2">
                                                        {(imgArrList && Array.isArray(imgArrList) && imgArrList.length > 0)? imgArrList.map((d,i) => {
                                                            return <span className="me-2 border border-2 rounded py-1 px-2" key={Math.random()}>{d} <FontAwesomeIcon icon={faSearch} onClick={e => handleViewImage(true, d)} className="dashboard-card-icon text-primay cursor-pointer" /> {(orginfo.roleId === 1 || orginfo.roleId === 3) ? <FontAwesomeIcon icon={faTrash} onClick={e => removeProductImage(i)} className="dashboard-card-icon text-danger" /> : null}</span>
                                                        }):null}
                                                    </div>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Color</label>
                                                    <Controller
                                                        name='colorId' 
                                                        defaultValue={InputFields.colorId}
                                                        rules={{required: rowid?false:true}}
                                                        control={control} 
                                                        render={({field}) => (
                                                            <select value={field.value || ""} className="form-control custom-select" name="colorId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={rowid?true:false}>
                                                                <option value=""> Choose...</option>
                                                                {(Array.isArray(ColorList) && ColorList.length > 0) ? ColorList.map(d => {
                                                                    return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                }):null}
                                                            </select>
                                                        )}
                                                    />	
                                                    <small className="form-text text-danger">
                                                        {errors.colorId?.type === "required" && "Please update color"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Size</label>
                                                    <Controller
                                                        name='sizeId' 
                                                        defaultValue={InputFields.sizeId}
                                                        rules={{required: rowid?false:true}}
                                                        control={control} 
                                                        render={({field}) => (
                                                            <select value={field.value || ""} className="form-control custom-select" name="sizeId" onChange={(e) => {updateChangeEvent(e, field)}} disabled={rowid?true:false}>
                                                                <option value=""> Choose...</option>
                                                                {(Array.isArray(SizeList) && SizeList.length > 0) ? SizeList.map(d => {
                                                                    return <option key={Math.random()} value={d.pk_id}>{d.label}</option>
                                                                }):null}
                                                            </select>
                                                        )}
                                                    />	
                                                    <small className="form-text text-danger">
                                                        {errors.sizeId?.type === "required" && "Please update size"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">SKU Id</label>
                                                    <input type="text" defaultValue={InputFields.skuId} className="form-control" name="skuId" {...register("skuId", { required: rowid?false:true, minLength: 1, maxLength: 125 })} disabled={rowid?true:false} />
                                                    <small className="form-text text-danger">
                                                        {errors.skuId?.type === "required" && "Sku Id is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.skuId?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.skuId?.type === "maxLength" && "Only 125 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <div><label htmlFor="deliveryDate" className="form-label fs-14rem fw-bold">Delivery Date</label></div>
                                                    <div className="react-datepicker-wrapper-fullwidth">
                                                        <Controller
                                                            control={control}
                                                            name="deliveryDate"
                                                            defaultValue={InputFields.deliveryDate} 
                                                            rules={{ required: rowid?false:true }}
                                                            render={({ field }) => {
                                                                return (
                                                                    <DatePicker
                                                                        className="form-control w-100"
                                                                        selected={field.value}
                                                                        disabled={rowid?true:false} 
                                                                        format="DD" 
                                                                        onChange={(date) => {
                                                                            field.onChange(date);
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <small className="form-text text-danger">
                                                        {errors.deliveryDate?.type === "required" && "Delivery date is mandatory"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Inward Quantity</label>
                                                    <input type="text" defaultValue={InputFields.inwardQty} className="form-control" name="inwardQty" {...register("inwardQty", { required: rowid?false:true, minLength: 1, maxLength: 7 })} disabled={rowid?true:false} />
                                                    <small className="form-text text-danger">
                                                        {errors.inwardQty?.type === "required" && "Inward Quantity is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.inwardQty?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.inwardQty?.type === "maxLength" && "Only 7 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Inward Suffix</label>
                                                    <input type="text" defaultValue={InputFields.inwardSuffix} placeholder="pcs,kit,box etc" className="form-control" name="inwardSuffix" {...register("inwardSuffix", { required: rowid?false:true, minLength: 1, maxLength: 12 })} disabled={rowid?true:false} />
                                                    <small className="form-text text-danger">
                                                        {errors.inwardSuffix?.type === "required" && "Inward Suffix Suffix is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.inwardSuffix?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.inwardSuffix?.type === "maxLength" && "Only 12 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Amount (Per Pcs)</label>
                                                    <input type="text" defaultValue={InputFields.amount} className="form-control" name="amount" {...register("amount", { required: rowid?false:true, minLength: 1, maxLength: 22 })} disabled={rowid?true:false} />
                                                    <small className="form-text text-danger">
                                                        {errors.amount?.type === "required" && "Amount is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.amount?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.amount?.type === "maxLength" && "Only 22 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Tax</label>
                                                    <input type="text" defaultValue={InputFields.tax} className="form-control" name="tax" {...register("tax", { required: rowid?false:true, minLength: 1, maxLength: 22 })} disabled={rowid?true:false} />
                                                    <small className="form-text text-danger">
                                                        {errors.tax?.type === "required" && "Tax is mandatory"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.tax?.type === "minLength" && "Enter atleast 1 characters"}
                                                    </small>
                                                    <small className="form-text text-danger">
                                                        {errors.tax?.type === "maxLength" && "Only 22 characters allowed"}
                                                    </small>
                                                </div>
                                                <div className={`col-md-4 mt-3 ${rowid?`d-none`:``}`}>
                                                    <label className="form-label fs-14rem fw-bold">Grand Total</label>
                                                    <div>0/-</div>
                                                </div>
                                                {(orginfo.roleId === 1 || orginfo.roleId === 2) ? <div className="col-12 text-end">
                                                    <button type="submit" disabled={DisableSubmitButton} className="vt-primary-btn text-end btn-lg text-white btn mt-4 fs-14rem">{SubmitButton}</button>
                                                </div> : null }
                                            </>}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary fs-14rem" onClick={hideModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {(BrowseImage)? <GalleryBox orgInfo={orginfo} handlegalleryimagenamefn={handleProductImageName} setbrowseimage={setBrowseImage} selectedimg={InputFields.productImg} token={token} action="selectable" />:null}
            {(PreviewImage)? <ImagePreview imagepath={PreviewImagePath} handleviewimagefn={handleViewImage} />:null}
        </>
    )
}

export default FormModal;